export function MobileMenu() {
    // VARS
    const $this = this;
    const hamburger = document.querySelector('[data-hamburger]');
    const menu = document.querySelector('[data-menu]');
    const hamburgerLine = document.querySelector('[data-hamburger-line]');

    // LISTENERS
    hamburger?.addEventListener('click', handleOnClickHamburger, false);

    // HANDLERS
    function handleOnClickHamburger() {
        $this.toggleMobileMenu();
    }

    // FUNCTIONS
    $this.toggleMobileMenu = function () {
        if (menu.classList.contains('animate')) {
            $this.closeMobileMenu();
        } else {
            $this.openMobileMenu();
        }
    };

    $this.openMobileMenu = function () {
        hamburgerLine.classList.add('active');
        menu.classList.add('animate');
        disableScroll();
    };

    $this.closeMobileMenu = function () {
        hamburgerLine.classList.remove('active');
        menu.classList.remove('animate');
        enableScroll();
    };
}
