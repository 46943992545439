import { scrollTo } from './helper-function/scrollTo';

export const initScrollToAnchor = () => {
    const links = document.querySelectorAll('[data-anchor-link-href]');
    if (!links.length) return;

    links.forEach((link) => {
        link.addEventListener('click', handleOnClickLink, false);
    });

    function handleOnClickLink(event) {
        const targetElem = document.querySelector(`${this.dataset.anchorLinkHref}`);
        if (targetElem) {
            event.preventDefault();
            window.mobileMenu.closeMobileMenu();
            scrollTo(this.dataset.anchorLinkHref, true, -70);
        }
    }
};
