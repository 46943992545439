import mixpanel from 'mixpanel-browser';
import { generateRandomString } from '../helper-functions/generateRandomString';
import { onLoadEvents } from './onLoadEvents';
import { getDeviceType } from '../helper-functions/getDeviceType';
import { onClickEvents } from './onClickEvents';
import { onSubmitEvents } from './onSubmitEvents';
import { onChangeEvents } from './onChangeEvents';
import { getUrlParameters } from '../helper-functions/getUrlParameters';

const parameters = getUrlParameters();

import { collectDataAttributes, collectFormData } from '../helper-functions/collectData';
import cloneDeep from 'lodash.clonedeep';
import { globalEventProperties } from './globalEventProperties';

export const initMixpannel = () => {
    const mixpannelKey = document.querySelector('[data-mixpannel-key]')?.dataset.mixpannelKey;
    if (!mixpannelKey) return;
    let userID = localStorage.getItem('userID');
    const params = new URL(document.location).searchParams;
    const userProps = {
        $device_type: getDeviceType(),
        $channel: 'direct',
    };

    if (params.get('split_group')) {
        userProps.split_group = params.get('split_group');
    }
    if (params.get('split_variant')) {
        userProps.split_variant = params.get('split_variant');
    }

    Object.assign(userProps, parameters);
    if (!Boolean(userID)) {
        userID = generateRandomString(40);
        localStorage.setItem('userID', userID);
    }

    mixpanel.init(mixpannelKey, {
        // debug: true, // for debug local
        persistence: 'localStorage',
        cross_site_cookie: true,
        loaded: function () {
            const userIdInputs = document.querySelectorAll('[data-mixpanel-user-id]');
            [...userIdInputs].forEach((input) => {
                input.value = userID;
            });
        },
    });

    mixpanel.identify(userID);

    if (params.get('utm_medium') === 'organic') {
        userProps.$channel = 'organic';
    } else if (params.get('aff_id')) {
        userProps.$channel = 'affiliate';
        userProps.$aff_id = params.get('aff_id');
    } else if (
        params.get('gclid') ||
        params.get('msclid') ||
        params.get('ttclid') ||
        params.get('utm_source') === 'facebook' ||
        params.get('utm_source') === 'bigo' ||
        params.get('utm_medium') === 'cpc'
    ) {
        userProps.$channel = 'ppc';
    } else if (params.get('push_id')) {
        userProps.$channel = 'retention';
        userProps.$push_id = params.get('push_id');
    } else if (params.get('mail_id')) {
        userProps.$channel = 'retention';
        userProps.$push_id = params.get('mail_id');
    } else if (params.get('shop_referrer_domain') || params.get('push_id')) {
        userProps.$channel = 'referral';
    }

    mixpanel.people.set(userProps);

    document.querySelectorAll('[data-mixpanel-track-submit]').forEach((form) => {
        mixpanel.track_forms(form, form.dataset.mixpanelEventName, function () {
            const props = cloneDeep(globalEventProperties);
            const formData = collectFormData(form);
            const mixpanelAttributes = collectDataAttributes(
                form.querySelector('button[type=submit]'),
                'data-mixpanel-',
            );

            return { ...formData, ...mixpanelAttributes, ...props };
        });
    });

    onLoadEvents();
    onClickEvents();
    onSubmitEvents();
    onChangeEvents();
};
